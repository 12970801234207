<template>
    <div>
        
        <div class="content content_top_margin" style="min-height: 175px;">
            <div class="content_inner  ">
                <div class="full_width ">
                    <div class="full_width_inner">

                        <div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
                            style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h2>Working from an Airbnb</h2>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-6">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>
                                                                                <router-link :to="{ name: 'wfl-posture-perfect-a45' }">
                                                                                        <em>Up
                                                                                        Next: </em> Posture Perfect <img
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
                                                                                        alt="" width="64"
                                                                                        height="37">
                                                                                </router-link>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-12">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <h3>What to pack</h3>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner "
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left   rounded">
                                                                        <div class="wpb_wrapper">

                                                                            <div
                                                                                class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                <img width="700" height="400"
                                                                                    src="../../assets/images/airbnb/Airbnb-Working-from-a-listing-What-to-Pack-1.jpg"
                                                                                    class="vc_single_image-img attachment-full"
                                                                                    alt="" loading="lazy"
                                                                                    title="Airbnb-Working-from-a-listing-What-to-Pack"
                                                                                    >
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <div class="wpb_wrapper">
                                                                            <p>Your listing is your home-away-from-home,
                                                                                but it might not have everything you
                                                                                need to work comfortably. With regards
                                                                                to ergonomics, you are best off if you
                                                                                pack the following things in your bag to
                                                                                take with you:</p>
                                                                            <ul>
                                                                                <li>Portable laptop riser</li>
                                                                                <li>External keyboard</li>
                                                                                <li>External mouse</li>
                                                                                <li>Inflatable lumbar support</li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                    <div class="vc_empty_space" style="height: 32px">
                                                                        <span class="vc_empty_space_inner">
                                                                            <span class="empty_space_image"></span>
                                                                        </span></div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="no-margin wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_text_column wpb_content_element  vc_custom_1637686186632 vc_custom_1637686104515">
                                                                        <div class="wpb_wrapper">
                                                                            <h2>Productivity On-the-Go</h2>
                                                                            <p>&nbsp;</p>
                                                                            <ul>
                                                                                <li>
                                                                                    <h3>Chargers and back-up chargers
                                                                                        are essential when on-the-go.
                                                                                    </h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                                <li>
                                                                                    <h3>Earbuds or earphones to tune out
                                                                                        distractions.</h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                                <li>
                                                                                    <h3>Bring along some power bars for
                                                                                        extra energy.</h3>
                                                                                    <p>&nbsp;</p>
                                                                                </li>
                                                                                <li>
                                                                                    <h3>Make sure your productivity apps
                                                                                        are updated.</h3>
                                                                                </li>
                                                                            </ul>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
                            <div class=" section_inner clearfix">
                                <div class="section_inner_margin clearfix">
                                    <div class="wpb_column vc_column_container vc_col-sm-12">
                                        <div class="vc_column-inner">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_text_column wpb_content_element ">
                                                    <div class="wpb_wrapper">
                                                        <h2>You may also like</h2>

                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                                <div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
                                                    style=" text-align:left;">
                                                    <div class=" full_section_inner clearfix">
                                                         <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="2560" height="1002"
                                                                                        src="../../assets/images/airbnb/REPLACEMENT-WORK-IN-CAFE-PARK-ETC2-scaled.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="REPLACEMENT - WORK IN CAFE, PARK ETC2"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                        <router-link :to="{ name: 'working-from-the-cafe-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working from the cafe</h4>
                                                                            <p>Working from the cafe? Ergo has your back
                                                                                while you get caffeinated.</p>

                                                                        </div>
                                                                        </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                         <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-in-groups-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="632" height="422"
                                                                                        src="../../assets/images/airbnb/3rd-Floor-SBM_SF-WFG-Airbnb-Ergo-Anywhere-sm.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="Airbnb-landing-Page---Working-From-Listing"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'working-in-groups-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working in group settings</h4>
                                                                            <p>Discover how to make ergo a valuable member of your team.</p>

                                                                        </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                                            <div class="vc_column-inner">
                                                                <div class="wpb_wrapper">
                                                                    <div
                                                                        class="wpb_single_image wpb_content_element vc_align_left">
                                                                        <div class="wpb_wrapper">
                                                                             <router-link :to="{ name: 'working-on-the-move-a45' }">
                                                                                <div
                                                                                    class="vc_single_image-wrapper   vc_box_border_grey">
                                                                                    <img width="1008" height="643"
                                                                                        src="../../assets/images/airbnb/Airbnb-Landing-Page-On-the-Move.jpg"
                                                                                        class="vc_single_image-img attachment-full"
                                                                                        alt="" loading="lazy"
                                                                                        title="3rd-Floor-SBM_SF - WFG Airbnb Ergo Anywhere (sm)"
                                                                                        >
                                                                                </div>
                                                                             </router-link>
                                                                        </div>
                                                                    </div>

                                                                    <div class="wpb_text_column wpb_content_element ">
                                                                         <router-link :to="{ name: 'working-on-the-move-a45' }">
                                                                        <div class="wpb_wrapper">
                                                                            <h4>Working on the move</h4>
                                                                            <p>Wanderlust catches us all. Try these tips to bring ergo anywhere.</p>

                                                                        </div>
                                                                         </router-link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="vc_empty_space" style="height: 32px"><span
                                                        class="vc_empty_space_inner">
                                                        <span class="empty_space_image"></span>
                                                    </span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="vc_row wpb_row section vc_row-fluid " style=" text-align:left;">
                            <div class=" full_section_inner clearfix">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                                                <div class="wpb_wrapper">
                                                    <div id="headerPopup" class="mfp-hide"
                                                        style="padding:56.25% 0 0 0;position:relative;"><iframe
                                                            src="https://player.vimeo.com/video/580346529?h=3561a2038c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen=""
                                                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                                            title="ERGO_in 30 seconds"></iframe></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->



                    </div>
                </div>
               

            </div>
        </div>

    </div>
</template>
<script>
    //import carousel from "vue-owl-carousel"
    export default {
        name: 'what-to-pack-a45',
    }
</script>